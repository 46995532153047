import { useContext } from 'react';
import { func } from 'prop-types';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

import {
 Button, Link, TextField, Typography 
} from '#atoms/index';
import { regexPhoneNumber } from '#helpers/regex';
import { postNewUser, getToken } from '#api/user';

import GlobalContext from '../../context/Global/GlobalContext';

const Register = ({ onClickLogin }) => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting }
  } = useForm();
  const { udpateUsernameOnboarding } = useContext(GlobalContext);
  const router = useRouter();

  const onSubmit = async data => {
    await postNewUser({
      username: data.email,
      password: data.password,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phone,
      TyC_accepted: 'true'
    });
    const response = await getToken({
      username: data.email,
      password: data.password,
      platform: 'zona-padres'
    });
    //save the username to onboarding
    udpateUsernameOnboarding(data.email);
    window.localStorage.setItem('accessToken', response.data.access);
    window.localStorage.setItem('refreshToken', response.data.refresh);
    router.push('/onboarding');
  };

  return (
    <section>
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="firstName"
          placeholder="Nombres"
          register={register({ required: 'El nombre es un campo obligatorio' })}
          error={!!errors.firstName}
        />
        {errors.firstName && (
          <div style={{ marginTop: '-15px', paddingBottom: '10px' }}>
            <Typography variant="body2" color="red">
              {errors.firstName.message}
            </Typography>
          </div>
        )}
        <TextField
          name="lastName"
          placeholder="Apellidos"
          register={register({ required: 'El apellido es un campo obligatorio' })}
          error={!!errors.lastName}
        />
        {errors.lastName && (
          <div style={{ marginTop: '-15px', paddingBottom: '10px' }}>
            <Typography variant="body2" color="red">
              {errors.lastName.message}
            </Typography>
          </div>
        )}
        <TextField
          name="email"
          placeholder="Email"
          register={register({
            required: 'El email es un campo obligatorio',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Esto no es un email, intenta con uno válido'
            }
          })}
          error={!!errors.email}
        />
        {errors.email && (
          <div style={{ marginTop: '-15px', paddingBottom: '10px' }}>
            <Typography variant="body2" color="red">
              {errors.email.message}
            </Typography>
          </div>
        )}
        <TextField
          name="phone"
          placeholder="Número teléfonico"
          register={register({
            required: 'El número teléfonico es un campo obligatorio',
            pattern: {
              value: regexPhoneNumber,
              message: 'Esto número teléfonico, intenta con uno válido'
            }
          })}
          error={!!errors.phone}
        />
        {errors.phone && (
          <div style={{ marginTop: '-15px', paddingBottom: '10px' }}>
            <Typography variant="body2" color="red">
              {errors.phone.message}
            </Typography>
          </div>
        )}
        <TextField
          name="password"
          placeholder="Contraseña"
          type="password"
          register={register({
            required: 'La contraseña es un campo obligatorio',
            minLength: {
              value: 6,
              message: 'La contraseña debe tener al menos 6 carácteres'
            }
          })}
          error={!!errors.password}
        />
        {errors.password && (
          <div style={{ marginTop: '-15px', paddingBottom: '10px' }}>
            <Typography variant="body2" color="red">
              {errors.password.message}
            </Typography>
          </div>
        )}

        <div className="login-form__terms">
          <Typography variant="body">
            Al hacer click en Registrarme estas de acuerdo con los
            <Link
              href="/terminos-y-condiciones"
              variant="underline"
              target="_blank"
              rel="noreferrer"
            >
              Terminos y Condiciones
            </Link>
            de la plataforma.
          </Typography>
        </div>

        <Button type="submit">{!isSubmitting ? 'REGISTRARME' : 'REGISTRANDO...'}</Button>
      </form>

      <div className="login-go-to-register-section">
        <Typography variant="body">
          ¿Ya tienes un cuenta?
          <Button variant="link" onClick={onClickLogin}>
            Ingresa
          </Button>
        </Typography>
      </div>
    </section>
  );
};

Register.propTypes = { onClickLogin: func };

export default Register;
