import { useContext, useEffect } from 'react';

import { useUserContext } from '#hooks/index';
import IconWorld from '#app/UI/atoms/icons/world';
import GlobalContext from '#context/Global/GlobalContext';
import { postCurrenciesByAlliance } from '#api/listCurrency';

const SelectorCurrency = ({
  setShowOptions,
  showOptions,
  countrySelected,
  changeCountry,
  alianza,
  hasAlliance
}) => {
  const { data: userData } = useUserContext();
  const { geolocalization_data } = useContext(GlobalContext);

  useEffect(() => {
    // getCurrencies();
  }, [hasAlliance, geolocalization_data.currency]);

  const getCurrencies = async () => {
    try {
      const res = await postCurrenciesByAlliance({alliance_name: hasAlliance ? alianza : null});
      changeCountry('USD');
    } catch (error) {}
  };

  return (
    <li className="header__access_link choose_country">
      <span>
        <IconWorld />{' '}
        <i>
          {' '}
          <p> {countrySelected} </p>{' '}
        </i>{' '}
      </span>
    </li>
  );
};

export default SelectorCurrency;
