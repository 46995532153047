import useWindowSize from '#app/hooks/useWindowSize';
import { useAnnouncementBarStatus } from '#components/Home/AnnouncementBar/announcement-bar';

export const heights = {
  desktop: {
    header: 80,
    announcementBar: 46
  },
  mobile: {
    header: 76,
    announcementBar: 52
  }
};

export const heightsToScroll = {
  desktop: {
    hidden: -heights.desktop.header,
    visible: -(heights.desktop.header + heights.desktop.announcementBar)
  },
  mobile: {
    hidden: -heights.mobile.header,
    visible: -(heights.mobile.header + heights.mobile.announcementBar)
  }
};

export const windowSize = () => {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return 'mobile';
  } else {
    return 'desktop';
  }
};

export const scrollToOffsetSize = ({ mobile, desktop }) => {
  const { isAnnouncementBarHidden } = useAnnouncementBarStatus();
  const size = windowSize();

  if (isAnnouncementBarHidden) {
    switch (size) {
      case 'mobile':
        return mobile.hidden;
      case 'desktop':
        return desktop.hidden;
    }
  } else {
    switch (size) {
      case 'mobile':
        return mobile.visible;
      case 'desktop':
        return desktop.visible;
    }
  }
};
