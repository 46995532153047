import { node, string } from 'prop-types';
import cx from 'classnames';

import { useAnnouncementBarStatus } from '#components/Home/AnnouncementBar/announcement-bar';
import useWindowSize from '#app/hooks/useWindowSize';

const Main = ({
 children, mainType, headerType, bkColor, showAnnouncement 
}) => {
  const { isHidden, height } = useAnnouncementBarStatus();
  const { isDesktop } = useWindowSize();

  return (
    <main
      className={cx(
        'main',
        { [`main--${headerType}`]: headerType },
        { [`main__content__bk_color--${bkColor}`]: bkColor }
      )}
      style={{ paddingTop: showAnnouncement && !isHidden && `${height}px` }}
    >
      <div className={cx('main__content', { [`main__content--${mainType}`]: mainType })}>
        {children}
      </div>
    </main>
  );
};

Main.propTypes = {
  children: node.isRequired,
  mainType: string,
  headerType: string
};

export default Main;
