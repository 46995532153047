const IconWorld = ({ fill = 'black', className, dataTestid = 'world' }) => (
  <svg
    className={className}
    data-testid={dataTestid}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={`${className}-path`}
      data-testid={`${dataTestid}-path`}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 4.49992C7.85778 4.49992 4.49992 7.85778 4.49992 11.9999C4.49992 16.1421 7.85778 19.4999 11.9999 19.4999C16.1421 19.4999 19.4999 16.1421 19.4999 11.9999C19.4999 7.85778 16.1421 4.49992 11.9999 4.49992ZM2.83325 11.9999C2.83325 6.93731 6.93731 2.83325 11.9999 2.83325C17.0625 2.83325 21.1666 6.93731 21.1666 11.9999C21.1666 17.0625 17.0625 21.1666 11.9999 21.1666C6.93731 21.1666 2.83325 17.0625 2.83325 11.9999Z"
      fill={fill}
    />
    <path
      className={`${className}-path-2`}
      data-testid={`${dataTestid}-path-2`}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.83325 11.9999C2.83325 11.4937 3.20635 11.0833 3.66659 11.0833H20.3333C20.7935 11.0833 21.1666 11.4937 21.1666 11.9999C21.1666 12.5062 20.7935 12.9166 20.3333 12.9166H3.66659C3.20635 12.9166 2.83325 12.5062 2.83325 11.9999Z"
      fill={fill}
    />
    <path
      className={`${className}-path-3`}
      data-testid={`${dataTestid}-path-3`}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25029 11.8622C9.31312 14.4631 10.2825 16.9671 12.0001 19.009C13.7177 16.9671 14.687 14.4631 14.7499 11.8622C14.687 9.26137 13.7177 6.75738 12.0001 4.71546C10.2825 6.75738 9.31312 9.26137 9.25029 11.8622ZM12.0001 3.40371L11.3233 2.83325C8.88057 5.30088 7.49239 8.50323 7.41695 11.8446C7.41668 11.8563 7.41668 11.8681 7.41695 11.8798C7.49239 15.2212 8.88057 18.4236 11.3233 20.8912C11.4969 21.0666 11.7426 21.1666 12.0001 21.1666C12.2576 21.1666 12.5032 21.0666 12.6769 20.8912C15.1196 18.4236 16.5078 15.2212 16.5832 11.8798C16.5835 11.8681 16.5835 11.8563 16.5832 11.8446C16.5078 8.50323 15.1196 5.30088 12.6769 2.83325L12.0001 3.40371Z"
      fill={fill}
    />
  </svg>
);
export default IconWorld;
