import { useState } from 'react';
import { func } from 'prop-types';
import { useForm } from 'react-hook-form';

import { Button, TextField, Typography } from '#atoms/index';
import { getRecoveryEmail } from '#api/user';

const Login = ({ onClickLogin }) => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting }
  } = useForm();
  const [emailSent, setEmailSent] = useState(false);
  const [isWrongCreds, setIsWrongCreds] = useState(false);

  const onSubmit = async data => {
    setEmailSent(false);
    setIsWrongCreds(false);
    try {
      await getRecoveryEmail({ email: data.email });
      setEmailSent(true);
    } catch {
      setIsWrongCreds(true);
    }
  };

  return (
    <section>
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="email"
          placeholder="Email"
          register={register({
            required: 'El email es un campo obligatorio',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Esto no es un email, intenta con uno válido'
            }
          })}
          error={!!errors.email}
        />
        {errors.email && (
          <Typography variant="body2" color="red">
            {errors.email.message}
          </Typography>
        )}
        <Button type="submit" disabled={isSubmitting}>
          {!isSubmitting ? 'ENVIAR' : 'ENVIANDO...'}
        </Button>
      </form>

      {isWrongCreds && (
        <Typography variant="body2" color="red">
          ¡Ups!, no existe una cuenta registrada con ese correo electrónico
        </Typography>
      )}

      {emailSent && (
        <div className="mb-4">
          <Typography variant="body" color="green">
            Se envió un mail con el link de recuperación de contraseña al correo
            ingresado, verifíquelo y siga los pasos.
          </Typography>
        </div>
      )}

      <div className="login-go-to-register-section">
        <Typography variant="body">
          ¿Ya tienes un cuenta?
          <Button variant="link" onClick={onClickLogin}>
            Ingresa
          </Button>
        </Typography>
      </div>
    </section>
  );
};

Login.propTypes = { onClickLogin: func };

export default Login;
