import cx from 'classnames';
import { useState } from 'react';
import dynamic from 'next/dynamic';
import { useForm } from 'react-hook-form';

import { useWindowSize } from '#hooks/index';
import { postSuscriptionNews } from '#api/enrollment';

import styles from './index.module.scss';

const IconFace = dynamic(import('#app/UI/atoms/icons/squareFace'));
const Typography = dynamic(import('#app/UI/atoms/typography-updated'));
const IconTwitter = dynamic(import('#app/UI/atoms/icons/circleTwitter'));
const IconFacebook = dynamic(import('#app/UI/atoms/icons/circleFacebook'));
const IconLinkedin = dynamic(import('#app/UI/atoms/icons/circleLinkedin'));
const IconInstagram = dynamic(import('#app/UI/atoms/icons/circleInstagram'));

const Footer = ({ hasAlliance }) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    clearErrors,
    formState: {}
  } = useForm({ mode: 'onChange' });
  const { isDesktop } = useWindowSize();
  const [notificationForm, setNotificationForm] = useState({
    isVisible: false,
    message: ''
  });

  const onSubmit = async params => {
    try {
      const response = await postSuscriptionNews(params);
      if (response.status === 200) {
        reset();
        clearErrors();
        setNotificationForm({
          isVisible: true,
          message: 'Se envió correctamente la información'
        });
        setTimeout(() => {
          setNotificationForm({
            isVisible: false,
            message: ''
          });
        }, 4000);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {/* <WhatsApp /> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <footer className={cx(styles.wrapper, 'pt-88 sm_pt-40')}>
          <div className={styles.container}>
            {!hasAlliance && (
              <section className={cx(styles.columns)}>
                <div className={cx(styles.about)}>
                  <Typography
                    className="text-white text-left sm_text-center mb-24 sm_mb-16"
                    tag="h4"
                  >
                    Sobre Crack The Code
                  </Typography>
                  <Typography
                    className="text-white text-left sm_text-center"
                    tag="body-small"
                  >
                    Somos una escuela que prepara con divertidos cursos en vivo a niños,
                    niñas y jóvenes a partir de los 5 años donde desarrollarán habilidades
                    clave para su futuro.
                  </Typography>
                </div>
                <div className={cx(styles.courses)}>
                  <Typography
                    className="text-white text-left sm_text-center mb-24 sm_mb-16"
                    tag="h4"
                  >
                    Links útiles
                  </Typography>
                  <Typography className={cx(styles.links, 'text-white')} tag="body-small">
                    <a href="/politicas-de-privacidad" target="_blank" rel="noreferrer">
                      Políticas de privacidad
                    </a>
                  </Typography>
                  <Typography className={cx(styles.links, 'text-white')} tag="body-small">
                    <a
                      href="https://wiki2.crackthecode.la/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Términos y condiciones
                    </a>
                  </Typography>
                </div>
                <div className={cx(styles.social)}>
                  <Typography
                    className="text-left typo-27 mb-32"
                    tag="h4"
                    style={{ color: '#ffffff' }}
                  >
                    Siguenos
                  </Typography>
                  <div className={cx(styles.icons)}>
                    <a
                      href="https://www.linkedin.com/company/crack-the-code-per%C3%BA/mycompany/verification/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconLinkedin />
                    </a>
                    <a
                      href="https://www.facebook.com/crackthecode.la"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconFacebook />
                    </a>
                    <a
                      href="https://www.instagram.com/crackthecode.la/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconInstagram />
                    </a>
                    <a
                      href="https://www.tiktok.com/@crackthecodelatam?lang=es"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconTwitter />
                    </a>
                  </div>
                </div>
              </section>
            )}

            <div
              className={cx(
                styles.extra,
                'sm_mt-24 mb-92 sm_mb-50',
                hasAlliance ? 'mt-80' : 'mt-90'
              )}
            >
              <IconFace />
              <Typography className="text-white text-left" tag="bpdy-small">
                2023 Crack the Code, All Rights Reserved
              </Typography>
            </div>
          </div>
        </footer>
      </form>
    </>
  );
};

export default Footer;
