import axios from 'axios';

import { baseUrl, baseUrlB2b } from './baseUrl';

//* enrollments endpoints

export const postInscribtionContact = async data => {
  const response = await fetch(`${baseUrl}/api/enrollments/inscription-contact/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
  return response;
};

//* support endpoints (send data of form)

export const postLeadContact = async data => {
  const response = await fetch(`${baseUrl}/api/supports/lead-contact/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
  return response;
};

export const postComplaintsBook = async data => {
  const response = await fetch(`${baseUrl}/api/supports/complaint/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
  return response;
};

//* purchase flow

export const postEnrollmentPathLogin = async data => {
  const response = await axios.post(
    `${baseUrl}/api/enrolments/enrol-logged-user/`,
    data,
    {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }}
  );
  return response;
};

export const postEnrollmentPathLoginTestMonth = async data => {
  const response = await axios.post(
    `${baseUrl}/api/test-month/enrol-logged-user/`,
    data,
    {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }}
  );
  return response;
};

//*email

export const sendEmailNewEnrollment = async enrolment_id => {
  const response = await fetch(
    `${baseUrl}/api/transactional-email/new-enrolment/${enrolment_id}/`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
      }
    }
  );
  return response.json();
};

//* hubspot

export const postCreateLeadHs = async data => {
  const response = await fetch(`${baseUrl}/api/hubspot/contacts/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  return response.json();
};

export const postCreateDealHs = async (id, data) => {
  const response = await fetch(`${baseUrl}/api/hubspot/contacts/${id}/deals/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  return response.json();
};

export const putContactHs = async (id, data) => {
  const response = await fetch(`${baseUrl}/api/hubspot/contacts/${id}/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  return response.json();
};

export const postCreateSupportMessage = async data => {
  const response = await axios.post(
    `${baseUrl}/api/hubspot/contacts-support-form/`,
    data,
    {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }}
  );
  return response;
};

export const postFreeClass = async data => {
  const response = await axios.post(
    'https://api.hsforms.com/submissions/v3/integration/submit/9402376/a996f7d3-f47d-47c6-9da6-b50a21f8dc81',
    data
  );
  return response;
};

export const postSuscriptionNews = async data => {
  const response = await axios.post(`${baseUrlB2b}/contact/newsletter`, data);
  return response;
};

//* FB Convertion API
export const postConvertionFBAddToCart = async data => {
  const response = await axios.post(`${baseUrl}/api/fb-integration/add-to-cart`, data);
  return response;
};

export const postConvertionFBPurchase = async data => {
  const response = await axios.post(`${baseUrl}/api/fb-integration/purchase`, data);
  return response;
};
