import { moodleUrl } from '#api/baseUrl';

export const navData = {
  programas: [
    {
      title: 'Cursos',
      url: '/cursos',
      redirect: null,
      data: null
    },
    {
      title: 'Rutas de aprendizaje',
      url: '/rutas',
      redirect: null,
      data: null
    }
  ],
  nosotros: [
    {
      title: 'Trabaja con nosotros',
      url: null,
      redirect:
        'https://crackthecodelatam.notion.site/Trabaja-en-Crack-The-Code-52dcea4847b54147a55bda4a06540ae8',
      data: null
    },
    {
      title: 'Contáctanos',
      url: null,
      redirect:
        'https://api.whatsapp.com/send?phone=17867892469&text=Deseo%20recibir%20información%20sobre%20Crack%20The%20Code',
      data: null
    }
  ],
  login: [
    {
      title: 'Soy estudiante',
      url: null,
      redirect: moodleUrl,
      data: null
    },
    {
      title: 'Soy organización',
      url: 'login-b2b',
      redirect: null,
      data: null
    },
    {
      title: 'Soy madre, padre o tutor',
      url: 'login',
      redirect: null,
      data: null
    }
  ]
};

export const navDataMobile = [
  {
    title: 'Proyectos',
    url: null,
    redirect: 'https://www.organizaciones.crackthecode.la/proyectos',
    data: null
  },
  {
    title: 'Blog',
    url: null,
    redirect: 'https://blog.crackthecode.la/',
    data: null
  },
  {
    title: 'Nosotros',
    url: null,
    redirect: null,
    data: [
      {
        title: 'Trabaja con nosotros',
        url: null,
        redirect:
          'https://crackthecodelatam.notion.site/Trabaja-en-Crack-The-Code-52dcea4847b54147a55bda4a06540ae8',
        data: null
      },
      {
        title: 'Contáctanos',
        url: null,
        redirect:
          'https://api.whatsapp.com/send?phone=17867892469&text=Deseo%20recibir%20información%20sobre%20Crack%20The%20Code',
        data: null
      }
    ]
  }
];

export default navData;
