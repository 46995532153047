import { eventGtag } from 'app/hooks/eventGtag';

export const listCurrency = {
  COP: {
    country: 'Colombia',
    countryCode: 'CO',
    currencyCode: 'COP',
    symbol: '$'
  },
  MXN: {
    country: 'México',
    countryCode: 'MX',
    currencyCode: 'MXN',
    symbol: '$'
  },
  PEN: {
    country: 'Perú',
    countryCode: 'PE',
    currencyCode: 'PEN',
    symbol: 'S/'
  },
  ARS: {
    country: 'Argentina',
    countryCode: 'AR',
    currencyCode: 'ARS',
    symbol: '$'
  },
  CRC: {
    country: 'Costa Rica',
    countryCode: 'CR',
    currencyCode: 'CRC',
    symbol: '₡'
  },
  UYU: {
    country: 'Uruguay',
    countryCode: 'UY',
    currencyCode: 'UYU',
    symbol: '$'
  },
  CLP: {
    country: 'Chile',
    countryCode: 'CL',
    currencyCode: 'CLP',
    symbol: '$'
  },
  USD: {
    country: 'Global',
    countryCode: 'US',
    currencyCode: 'USD',
    symbol: 'US$'
  }
};

const DLocalArray = [
  {
    country: 'MX',
    documentType: 'CURP',
    documentFormatMin: '10',
    documentFormatMax: '18'
  },
  {
    country: 'PE',
    documentType: 'DNI',
    documentFormatMin: '8',
    documentFormatMax: '9'
  },
  {
    country: 'AR',
    documentType: 'DNI',
    documentFormatMin: '7',
    documentFormatMax: '9'
  },
  {
    country: 'CO',
    documentType: 'CC',
    documentFormatMin: '6',
    documentFormatMax: '10'
  },
  {
    country: 'CL',
    documentType: 'CI',
    documentFormatMin: '8',
    documentFormatMax: '9'
  },
  {
    country: 'CR',
    documentType: 'CI',
    documentFormatMin: '9',
    documentFormatMax: '9'
  },
  {
    country: 'UY',
    documentType: 'CI',
    documentFormatMin: '6',
    documentFormatMax: '8'
  }
];

export const validateDocumentFormat = (country, documentFormat) => {
  const entry = DLocalArray.find(
    item => item.country.toLowerCase() === country.toLowerCase()
  );

  if (!entry) {
    return { validCountry: false, validDocument: true };
  }

  const { documentFormatMin: min, documentFormatMax: max } = entry;

  const cleanedFormat = documentFormat.replace(/\s/g, '');

  const value =
    cleanedFormat.length >= Number(min) && cleanedFormat.length <= Number(max);

  return { validCountry: true, validDocument: value };
};

export function validateCountry(
  countryCode,
  setCountry,
  setCurrency,
  eventNameAnalytics,
  userData
) {
  setCountry && setCountry('Global US$');
  setCurrency && setCurrency('USD');
  window.localStorage.setItem('country', 'US');
  window.localStorage.setItem('currency', 'USD');
  eventNameAnalytics && userData && eventGtag(`${eventNameAnalytics}_USD`, userData);
  return;

  if (countryCode == 'PE') {
    setCountry && setCountry('Perú S/');
    setCurrency && setCurrency('PEN');
    window.localStorage.setItem('country', 'PE');
    window.localStorage.setItem('currency', 'PEN');
    eventNameAnalytics && userData && eventGtag(`${eventNameAnalytics}_PEN`, userData);
  } else if (countryCode == 'MX') {
    setCountry && setCountry('México $');
    setCurrency && setCurrency('MXN');
    window.localStorage.setItem('country', 'MX');
    window.localStorage.setItem('currency', 'MXN');
    eventNameAnalytics && userData && eventGtag(`${eventNameAnalytics}_MXN`, userData);
  } else if (countryCode == 'CO') {
    setCountry && setCountry('Colombia $');
    setCurrency && setCurrency('COP');
    window.localStorage.setItem('country', 'CO');
    window.localStorage.setItem('currency', 'COP');
    eventNameAnalytics && userData && eventGtag(`${eventNameAnalytics}_COP`, userData);
  } else if (countryCode == 'AR') {
    setCountry && setCountry('Argentina $');
    setCurrency && setCurrency('ARS');
    window.localStorage.setItem('country', 'AR');
    window.localStorage.setItem('currency', 'ARS');
    eventNameAnalytics && userData && eventGtag(`${eventNameAnalytics}_ARS`, userData);
  } else if (countryCode == 'CL') {
    setCountry && setCountry('Chile $');
    setCurrency && setCurrency('CLP');
    window.localStorage.setItem('country', 'CL');
    window.localStorage.setItem('currency', 'CLP');
    eventNameAnalytics && userData && eventGtag(`${eventNameAnalytics}_CLP`, userData);
  } else if (countryCode == 'CR') {
    setCountry && setCountry('Costa Rica ₡');
    setCurrency && setCurrency('CRC');
    window.localStorage.setItem('country', 'CR');
    window.localStorage.setItem('currency', 'CRC');
    eventNameAnalytics && userData && eventGtag(`${eventNameAnalytics}_CRC`, userData);
  } else if (countryCode == 'UY') {
    setCountry && setCountry('Uruguay $');
    setCurrency && setCurrency('UYU');
    window.localStorage.setItem('country', 'UY');
    window.localStorage.setItem('currency', 'UYU');
    eventNameAnalytics && userData && eventGtag(`${eventNameAnalytics}_UYU`, userData);
  } else {
    setCountry && setCountry('Global US$');
    setCurrency && setCurrency('USD');
    window.localStorage.setItem('country', 'US');
    window.localStorage.setItem('currency', 'USD');
    eventNameAnalytics && userData && eventGtag(`${eventNameAnalytics}_USD`, userData);
  }
}

export function validateCountryGeo(countryCode) {
  return {
    asn: '',
    asnName: '',
    asnOrg: '',
    businessName: '',
    businessWebsite: '',
    city: 'Miami',
    continent: 'North America',
    country: 'United States',
    countryCode: 'US',
    currency: 'USD',
    currencySymbol: 'USD $',
    ipAddress: null,
    ipName: '',
    ipType: 'Residential',
    isp: 'M247 Europe SRL',
    lat: '25.77427',
    loading: false,
    lon: '-80.19366',
    org: 'M247 Europe SRL',
    query: '146.70.183.163',
    region: 'Florida',
    status: 'success',
    timezone: 'America/New_York',
    utcOffset: '-04:00'
  };
  if (countryCode == 'PE') {
    return {
      asn: 'AS146955',
      asnName: 'FLAMENETWORKS1-AS-AP',
      asnOrg: 'Flame Networks Private Limited',
      businessName: '',
      businessWebsite: '',
      city: 'Lima',
      continent: 'South America',
      country: 'Peru',
      countryCode: 'PE',
      currency: 'PEN',
      currencySymbol: 'S/',
      ipAddress: null,
      ipName: '',
      ipType: 'Residential',
      isp: 'Flame Networks Private Limited',
      lat: '-12.0555',
      loading: false,
      lon: '-77.02637',
      org: 'Flame Networks Private Limited',
      query: '102.38.204.1',
      region: 'Lima region',
      status: 'success',
      timezone: 'America/Lima',
      utcOffset: '-05:00'
    };
  } else if (countryCode == 'MX') {
    return {
      asn: 'AS7018',
      asnName: 'ATT-INTERNET4',
      asnOrg: 'AT&T Services, Inc.',
      businessName: '',
      businessWebsite: '',
      city: 'Mexico City',
      continent: 'North America',
      country: 'Mexico',
      countryCode: 'MX',
      currency: 'MXN',
      currencySymbol: 'MXN $',
      ipAddress: null,
      ipName: '',
      ipType: 'Residential',
      isp: 'AT&T Services, Inc.',
      lat: '19.42847',
      loading: false,
      lon: '-99.12766',
      org: 'AT&T Services, Inc.',
      query: '84.252.113.1',
      region: 'Mexico City',
      status: 'success',
      timezone: 'America/Mexico_City',
      utcOffset: '-05:00'
    };
  } else if (countryCode == 'CO') {
    return {
      asn: 'AS174',
      asnName: 'NexLink Communications',
      asnOrg: '',
      businessName: '',
      businessWebsite: '',
      city: 'Bogota',
      continent: 'South America',
      country: 'Colombia',
      countryCode: 'CO',
      currency: 'COP',
      currencySymbol: 'COP $',
      ipAddress: null,
      ipName: '',
      ipType: 'Residential',
      isp: '',
      lat: '4.60971',
      loading: false,
      lon: '-74.08175',
      org: '',
      query: '154.47.16.82',
      region: 'Bogota D.C.',
      status: 'success',
      timezone: 'America/Bogota',
      utcOffset: '-05:00'
    };
  } else if (countryCode == 'AR') {
    return {
      asn: 'AS7018',
      asnName: 'ATT-INTERNET4',
      asnOrg: 'AT&T Services, Inc.',
      businessName: '',
      businessWebsite: '',
      city: 'Buenos Aires',
      continent: 'South America',
      country: 'Argentina',
      countryCode: 'AR',
      currency: 'ARS',
      currencySymbol: 'ARS $',
      ipName: '',
      ipType: 'Residential',
      isp: 'AT&T Services, Inc.',
      lat: '-34.61315',
      lon: '-58.37723',
      org: 'AT&T Services, Inc.',
      query: '84.252.114.1',
      region: 'Buenos Aires F.D.',
      status: 'success',
      timezone: 'America/Argentina/Buenos_Aires',
      utcOffset: '-03:00'
    };
  } else if (countryCode == 'CL') {
    return {
      asn: 'AS146955',
      asnName: 'FLAMENETWORKS1-AS-AP',
      asnOrg: 'Flame Networks Private Limited',
      businessName: '',
      businessWebsite: '',
      city: 'Santiago',
      continent: 'South America',
      country: 'Chile',
      countryCode: 'CL',
      currency: 'CLP',
      currencySymbol: 'CLP $',
      ipName: '',
      ipType: 'Residential',
      isp: 'Flame Networks Private Limited',
      lat: '-33.45694',
      lon: '-70.64827',
      org: 'Flame Networks Private Limited',
      query: '102.38.199.1',
      region: 'Santiago Metropolitan',
      status: 'success',
      timezone: 'America/Santiago',
      utcOffset: '-03:00'
    };
  } else if (countryCode == 'CR') {
    return {
      asn: 'AS12679',
      asnName: 'ASN-MOL',
      asnOrg: 'Sokolov Dmitry Nikolaevich',
      businessName: '',
      businessWebsite: '',
      city: 'San Jose',
      continent: 'North America',
      country: 'Costa Rica',
      countryCode: 'CR',
      currency: 'CRC',
      currencySymbol: 'CRC ₡',
      ipName: '',
      ipType: 'Residential',
      isp: 'Sokolov Dmitry Nikolaevich',
      lat: '9.93333',
      lon: '-84.08333',
      org: 'Sokolov Dmitry Nikolaevich',
      query: '94.137.94.1',
      region: 'San Jose',
      status: 'success',
      timezone: 'America/Costa_Rica',
      utcOffset: '-06:00'
    };
  } else if (countryCode == 'UY') {
    return {
      asn: 'AS6057',
      asnName: '',
      asnOrg: 'Administracion Nacional de Telecomunicaciones',
      businessName: '',
      businessWebsite: '',
      city: 'Montevideo',
      continent: 'South America',
      country: 'Uruguay',
      countryCode: 'UY',
      currency: 'UYU',
      currencySymbol: 'UYU $',
      ipName: '',
      ipType: 'Residential',
      isp: 'Administracion Nacional de Telecomunicaciones',
      lat: '-34.90328',
      lon: '-56.18816',
      org: 'Administracion Nacional de Telecomunicaciones',
      query: '179.27.200.26',
      region: 'Montevideo',
      status: 'success',
      timezone: 'America/Montevideo',
      utcOffset: '-03:00'
    };
  } else {
    return {
      asn: '',
      asnName: '',
      asnOrg: '',
      businessName: '',
      businessWebsite: '',
      city: 'Miami',
      continent: 'North America',
      country: 'United States',
      countryCode: 'US',
      currency: 'USD',
      currencySymbol: 'USD $',
      ipAddress: null,
      ipName: '',
      ipType: 'Residential',
      isp: 'M247 Europe SRL',
      lat: '25.77427',
      loading: false,
      lon: '-80.19366',
      org: 'M247 Europe SRL',
      query: '146.70.183.163',
      region: 'Florida',
      status: 'success',
      timezone: 'America/New_York',
      utcOffset: '-04:00'
    };
  }
}
