// all events must validate the "userData" parameter
export const eventGtag = (eventName, userData, infoToAnalyze) => {
  if (
    window.gtag !== 'undefined' &&
    window !== 'undefined' &&
    typeof window.gtag === 'function'
  ) {
    if (eventName) {
      if (userData && infoToAnalyze) {
        window.gtag('event', eventName, {
          user_id: userData.id,
          first_name: userData.firstName,
          last_name: userData.lastName,
          email: userData.email,
          ...infoToAnalyze
        });
      } else if (infoToAnalyze) {
        window.gtag('event', eventName, { ...infoToAnalyze });
      } else {
        window.gtag('event', eventName);
      }
    } else {
      window.gtag('event', 'unnamed_event', {
        event_name: 'unnamed_event',
        page: window.location.href,
        gtm_element: window.dataLayer[19]
      });
    }
  }
};
