import { create } from 'zustand';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { bool, node, string } from 'prop-types';
import 'react-loading-skeleton/dist/skeleton.css';
import { useEffect, useState, useContext } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { SideModal } from '#components/index';
import useUserContext from '#hooks/useUserContext';
import { getConfig, getUserGeolocalization } from '#api/user';
import useNewUserGeolocalization from '#hooks/useNewUserGeolocalization';
import { validateCountry } from '#helpers/validate-country';

import Main from './Main/main';
import Header from './Header/header';
import Footer from './Footer/footer';
import LayoutContext from './context';
import Login from './login-register/login';
import Register from './login-register/register';
import GlobalContext from '../context/Global/GlobalContext';
import RecoveryPassword from './login-register/recovery-password';

export const useHeaderSliders = create(set => ({
  sliders: null,
  setSliders: sliders => set({ sliders: sliders })
}));

const Layout = ({
  logoLY = true,
  navbarLY = true,
  accessLY = true,
  footerLY = true,
  freeClassLY = true,
  informationLY = false,
  onlySelectCountry,
  noLogin,
  shadow,
  children,
  layoutType,
  headerType,
  mainType,
  bkColor,
  whatsAppIcon,
  solo = false,
  showAnnouncement = false,
  currency,
  setCurrency,
  alianza,
  hasAlliance,
  allianceLogo
}) => {
  const geo = useNewUserGeolocalization();
  const { geolocalization_data, updateGeolocalizationData } = useContext(GlobalContext);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isRecoveryPasswordOpen, setIsRecoveryPasswordOpen] = useState(false);
  const { loading, data } = useUserContext({ withVerification: true });
  const [countrySelected, setCountrySelected] = useState('');
  const { updateSettings } = useContext(GlobalContext);

  const contextData = { setIsLoginOpen };

  const getConfigGlobal = async () => {
    try {
      const { data } = await getConfig();
      updateSettings(data);
    } catch {
      console.log('problemas al cargar la configuración global');
    }
  };

  useEffect(() => {
    getConfigGlobal();
    if (layoutType === 'snap-scrolling') {
      window.document.body.classList.add('snap-scrolling');
    } else {
      window.document.body.classList.remove('snap-scrolling');
    }
  }, [layoutType]);

  useEffect(() => {
    //geo use context

    if (!geolocalization_data.loading && geolocalization_data.countryCode) {
      selectCountry(geolocalization_data.countryCode);
      validateCountry(geolocalization_data.countryCode, setCountrySelected, setCurrency);
      updateGeolocalizationData({
        ...geolocalization_data,
        ipAddress: geo.ipAddress || geo.query
      });
    } else {
      fetchGeolocalization();
    }
  }, []);

  const selectCountry = countryCode => {
    if (countryCode == 'MX') {
      setCountrySelected('México $');
    } else if (countryCode == 'CO') {
      setCountrySelected('Colombia $');
    } else if (countryCode == 'PE') {
      setCountrySelected('Perú S/');
    } else if (countryCode == 'AR') {
      setCountrySelected('Argentina $');
    } else if (countryCode == 'UY') {
      setCountrySelected('Uruguay $');
    } else if (countryCode == 'CL') {
      setCountrySelected('Chile $');
    } else if (countryCode == 'CR') {
      setCountrySelected('Costa Rica $');
    } else {
      setCountrySelected('Global US$');
    }
  };

  const fetchGeolocalization = async () => {
    try {
      const response = await getUserGeolocalization();
      let currency;
      let currencySymbol;
      if (response.countryCode === 'MX') {
        currency = 'MXN';
        currencySymbol = 'MXN $';
      } else if (response.countryCode === 'PE') {
        currency = 'PEN';
        currencySymbol = 'S/';
      } else if (response.countryCode === 'CO') {
        currency = 'COP';
        currencySymbol = 'COP $';
      } else if (response.countryCode === 'AR') {
        currency = 'ARS';
        currencySymbol = 'ARS $';
      } else if (response.countryCode === 'CI') {
        currency = 'CLP';
        currencySymbol = 'CLP $';
      } else if (response.countryCode === 'UYU') {
        currency = 'UYU';
        currencySymbol = 'UYU $';
      } else if (response.countryCode === 'CR') {
        currency = 'CRC';
        currencySymbol = 'CRC ₡';
      } else {
        currency = 'USD';
        currencySymbol = 'USD $';
      }

      //geo use context
      if (response) {
        updateGeolocalizationData({
          ...response,
          currency,
          currencySymbol,
          loading: false
        });
        validateCountry(response.countryCode, setCountrySelected, setCurrency);
        window.localStorage.removeItem('userGeolocationData');
      }
    } catch (error) {
      console.error('Error fetching geolocation:', error);
    }
  };

  return (
    <LayoutContext.Provider value={contextData}>
      {/* Header */}
      {!solo && !hasAlliance && (
        <Header
          shadow={shadow}
          logoLY={logoLY}
          navbarLY={navbarLY}
          accessLY={accessLY}
          userAndLogin={true}
          freeClassLY={freeClassLY}
          noLogin={noLogin}
          informationLY={informationLY}
          headerType={headerType}
          userContextData={{ loading, data }}
          onlySelectCountry={onlySelectCountry}
          showAnnouncement={showAnnouncement}
          countrySelected={countrySelected}
          setCountrySelected={setCountrySelected}
          setCurrency={setCurrency}
        />
      )}

      {hasAlliance && (
        <Header
          shadow={shadow}
          logoLY={logoLY}
          headerType={headerType}
          userContextData={{ loading, data }}
          onlySelectCountry={onlySelectCountry}
          showAnnouncement={showAnnouncement}
          countrySelected={countrySelected}
          setCountrySelected={setCountrySelected}
          setCurrency={setCurrency}
          alianza={alianza}
          hasAlliance={hasAlliance}
          allianceLogo={allianceLogo}
        />
      )}

      {/* Main */}
      {layoutType === 'snap-scrolling' && children}
      {!layoutType && (
        <Main
          mainType={mainType}
          headerType={headerType}
          whatsAppIcon={whatsAppIcon}
          bkColor={bkColor}
          showAnnouncement={showAnnouncement}
        >
          {children}
        </Main>
      )}

      {/* Footer */}
      {!solo && footerLY && <Footer hasAlliance={hasAlliance} />}

      {/* Sessions */}
      {isLoginOpen && (
        <SideModal title="Ingresa" onClose={() => setIsLoginOpen(false)}>
          <Login
            onClickRegister={() => setIsLoginOpen(false) || setIsRegisterOpen(true)}
            onClickRecovery={() =>
              setIsLoginOpen(false) || setIsRecoveryPasswordOpen(true)
            }
          />
        </SideModal>
      )}
      {isRegisterOpen && (
        <SideModal title="Regístrate" onClose={() => setIsRegisterOpen(false)}>
          <Register
            onClickLogin={() => setIsRegisterOpen(false) || setIsLoginOpen(true)}
          />
        </SideModal>
      )}
      {isRecoveryPasswordOpen && (
        <SideModal
          title="Recuperar mi Contraseña"
          onClose={() => setIsRecoveryPasswordOpen(false)}
        >
          <RecoveryPassword
            onClickLogin={() => setIsRecoveryPasswordOpen(false) || setIsLoginOpen(true)}
          />
        </SideModal>
      )}
    </LayoutContext.Provider>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  layoutType: string,
  headerType: string,
  mainType: string,
  whatsAppIcon: bool,
  solo: bool
};

export default Layout;
