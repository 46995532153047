import axios from 'axios';
import CryptoJS from 'crypto-js';

// all events must validate the "userData" parameter
export const eventFb = (eventName, userData, infoToAnalyze) => {
  if (
    window.fbq !== 'undefined' &&
    window !== 'undefined' &&
    typeof window.fbq === 'function'
  ) {
    if (eventName) {
      if (userData && infoToAnalyze) {
        window.fbq('track', eventName, {
          user_id: userData.id,
          first_name: userData.firstName,
          last_name: userData.lastName,
          email: userData.email,
          ...infoToAnalyze
        });
      } else if (infoToAnalyze) {
        window.fbq('track', eventName, { ...infoToAnalyze });
      } else {
        window.fbq('track', eventName);
      }
    } else {
      window.fbq('track', 'unnamed_event', {
        event_name: 'unnamed_event',
        page: window.location.href,
        gtm_element: window.dataLayer[19]
      });
    }
  }
};
//NOTE: SIMULATE PAYLOAD https://developers.facebook.com/docs/marketing-api/conversions-api/payload-helper
//NOTE: PARAMETERS https://developers.facebook.com/docs/marketing-api/conversions-api/parameters?locale=es_LA
//NOTE: PARAMETERS SERVER EVENTS https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/server-event?locale=es_LA
export const postConversionMeta = async (
  { event_name, user_data, custom_data },
  geolocalization_data
) => {
  const baseUrl = 'https://graph.facebook.com';
  const API_VERSION = 'v17.0';
  const PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;
  const ACCESS_TOKEN = process.env.NEXT_PUBLIC_FB_ACCESS_TOKEN;

  const dataConversionMeta = {
    data: [
      {
        event_name: event_name,
        event_time: Math.floor(Date.now() / 1000),
        action_source: 'website',
        user_data: {
          ...user_data,
          client_ip_address: geolocalization_data.ipAddress || geolocalization_data.query,
          client_user_agent: navigator.userAgent || navigator.vendor || window.opera
        },
        custom_data: custom_data
      }
    ]
  };

  try {
    const { data } = await axios.post(
      `${baseUrl}/${API_VERSION}/${PIXEL_ID}/events?access_token=${ACCESS_TOKEN}`,
      dataConversionMeta
    );
    return data;
  } catch (error) {}
};

export const generateHash = text => CryptoJS.SHA256(text).toString();

export const generateHashUserDataMeta = text => generateHash(text?.trim().toLowerCase());
