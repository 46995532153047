import { useEffect, useState } from 'react';

import { getUserGeolocalization } from '#api/user';

const useNewUserGeolocalization = () => {
  const [dataGeo, setDataGeo] = useState({
    loading: true,
    country: null,
    countryCode: null,
    city: null,
    continent: null,
    region: null,
    ipAddress: null,
    currency: null,
    currencySymbol: null,
    timezone: null,
    status: null
  });

  useEffect(() => {
    // Función anónima para hacer la petición a la API de geolocalización
    const fetchGeolocalization = async () => {
      try {
        const response = await getUserGeolocalization();
        let currency;
        let currencySymbol;

        if (response.countryCode === 'MX') {
          currency = 'MXN';
          currencySymbol = 'MXN $';
        } else if (response.countryCode === 'PE') {
          currency = 'PEN';
          currencySymbol = 'S/';
        } else if (response.countryCode === 'CO') {
          currency = 'COP';
          currencySymbol = 'COP $';
        } else if (response.countryCode === 'AR') {
          currency = 'ARS';
          currencySymbol = 'ARS $';
        } else if (response.countryCode === 'CL') {
          currency = 'CLP';
          currencySymbol = 'CLP $';
        } else if (response.countryCode === 'CR') {
          currency = 'CRC';
          currencySymbol = 'CRC ₡';
        } else if (response.countryCode === 'UY') {
          currency = 'UYU';
          currencySymbol = 'UYU $';
        } else {
          currency = 'USD';
          currencySymbol = 'USD $';
        }

        setDataGeo({
          loading: false,
          country: response.country,
          countryCode: response.countryCode,
          city: response.city,
          continent: response.continent,
          region: response.continent,
          ipAddress: response.query,
          currency: currency,
          currencySymbol: currencySymbol,
          timezone: response.timezone,
          status: response.status
        });
      } catch (error) {
        console.error('Error fetching geolocation:', error);
        setDataGeo({
          loading: false,
          country: 'United States',
          countryCode: 'US',
          city: 'Houston',
          continent: 'North America',
          region: 'Texas',
          ipAddress: '',
          currency: 'USD',
          currencySymbol: 'USD $',
          timezone: 'America/Chicago',
          status: 'error'
        });
      }
    };

    fetchGeolocalization();
  }, []);

  return dataGeo;
};

export default useNewUserGeolocalization;
