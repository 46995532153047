// ! regex to forms and other stuffs

// * regex to date like 01/01/2000 | 31/01/2000 | 01-1-2000 | 1.1.2019
export const regexDate = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/i;

// * regex to phone number like +919367788755 | 8989829304 | +16308520397 | 786-307-3615
/* export const regexPhoneNumber = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i; */
export const regexPhoneNumber =
  /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/i;

// * regex to email
export const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// * regex age
export const regexAge = /^[1-9]\d*$/i;

// * regex currency
export const regexNumber = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
