/* eslint-disable complexity */
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { shape, string } from 'prop-types';
import { animateScroll as scroll } from 'react-scroll';
import { useContext, useEffect, useState } from 'react';

import { heights } from '#app/helpers/scroll-to';
import { eventGtag } from '#app/hooks/eventGtag';
import useWindowSize from '#hooks/useWindowSize';
import useUserContext from '#hooks/useUserContext';
import { ButtonComponent, Logo } from '#atoms/index';
import { navDataMobile } from '#helpers/navbar-data';
import { validateCountry, validateCountryGeo } from '#helpers/validate-country';
import { useAnnouncementBarStatus } from '#components/Home/AnnouncementBar/announcement-bar';

import GlobalContext from '../../context/Global/GlobalContext';
import SelectorCurrency from './Components/SelectorCurrency/selectorCurrencyV2';

const DynamicUserDropdown = dynamic(() =>
  import('./Components/UserDropdown/user-dropdown')
);
const DynamicDropdown = dynamic(() => import('./Components/Dropdown/dropdown'));
const DynamicSidebar = dynamic(() => import('./Components/Sidebar/sidebar'));
const Button = dynamic(() => import('../../app/UI/molecules/button/button'));
const NewButton = dynamic(() => import('#app/UI/molecules/new-button'));

const HeaderLogo = ({ path, alianza, hasAlliance }) => {
  if (hasAlliance) {
    return (
      <image className="header__logo" onClick={() => eventGtag('logo_menu_alianza')}>
        <Logo color="dark" alianza={alianza} hasAlliance={hasAlliance} />
      </image>
    );
  } else {
    switch (path) {
      case '/':
        return (
          <image
            className="header__logo"
            onClick={() => {
              scroll.scrollToTop();
              eventGtag('logo_menu');
            }}
          >
            <Logo color="dark" />
          </image>
        );
      default:
        return (
          <image className="header__logo" onClick={() => eventGtag('logo_menu')}>
            <Logo color="dark" />
          </image>
        );
    }
  }
};

const Header = ({
  logoLY,
  navbarLY,
  accessLY,
  userAndLogin,
  freeClassLY,
  informationLY,
  headerType,
  shadow = false,
  userContextData,
  onlySelectCountry,
  noLogin,
  showAnnouncement,
  setCurrency,
  countrySelected,
  setCountrySelected,
  alianza,
  hasAlliance,
  allianceLogo
}) => {
  const router = useRouter();
  const { updateGeolocalizationData } = useContext(GlobalContext);
  const { isMobile, isDesktop } = useWindowSize();
  const { data: userData } = useUserContext();
  const [forceBgWhite, setForceBgWhite] = useState(shadow);
  const [showOptions, setShowOptions] = useState(false);
  const [showOptionsLogIn, setShowOptionsLogIn] = useState(false);
  const { isHidden, height } = useAnnouncementBarStatus();

  const {
 cleanPrevPath, isLoginUser, updateCurrentPosition, cleanPurchaseData 
} =
    useContext(GlobalContext);

  const handleScroll = () => {
    if (!shadow) {
      let wrapper = window;
      if (headerType === 'appear-on-scroll') {
        wrapper = document.getElementById('__next');
        setForceBgWhite(wrapper.scrollTop > 40);
      } else {
        setForceBgWhite(wrapper.scrollY >= 40);
      }
    }
  };

  // User Logout
  const onClickLogout = () => {
    window.localStorage.removeItem('userData');
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    isLoginUser(false);
    updateCurrentPosition('register');
    cleanPurchaseData();
    cleanPrevPath();
    router.push('/login');
    eventGtag('cerrar_sesion_menu', userData);
  };

  const changeCountry = countryCode => {
    //TODO: geo usecontext
    const changeDataGeoContext = validateCountryGeo(countryCode);

    updateGeolocalizationData({ ...changeDataGeoContext });
    validateCountry(
      countryCode,
      setCountrySelected,
      setCurrency,
      'selector_moneda',
      userData
    );
    window.localStorage.removeItem('userGeolocationData');
    setShowOptions(false);
  };

  useEffect(() => {
    let wrapper = window;
    if (headerType === 'appear-on-scroll') wrapper = document.getElementById('__next');
    wrapper.addEventListener('scroll', handleScroll, { passive: true });
    return () => wrapper.removeEventListener('scroll', handleScroll);
  }, []);

  switch (isMobile) {
    // Mobile
    case true:
      return (
        <>
          <header
            className={cx('header-Wrapper')}
            style={{
              marginTop:
                showAnnouncement && !isHidden
                  ? `${forceBgWhite ? '52px' : '65px'}`
                  : forceBgWhite
                  ? '0px'
                  : '22px'
            }}
          >
            <div className={cx('header', { ['header--shadow']: forceBgWhite })}>
              {/* Logo */}
              {logoLY && (
                <HeaderLogo
                  path={router.asPath}
                  alianza={alianza}
                  hasAlliance={hasAlliance}
                />
              )}

              {/* Access */}
              {navbarLY ||
                (false && (
                  <div className="header__access">
                    {/* Contáctanos  */}
                    <ButtonComponent
                      description="Prueba una clase"
                      variantPage="curso"
                      variantButton="black"
                      align="left"
                      href={'/clase-gratis?utm_source=web&utm_medium=announcement%20bar'}
                    />
                  </div>
                ))}

              {/* Information /myvideogame/[slug].js */}
              {informationLY && (
                <Button
                  type="scroll"
                  scrollOptions={{
                    to: 'contact',
                    offset: {
                      mobile: {
                        hidden: -heights.mobile.header,
                        visible: 0
                      },
                      desktop: {
                        hidden: -heights.desktop.header,
                        visible: 0
                      }
                    }
                  }}
                  variant="black"
                  width="215px"
                  height="40px"
                  textOptions={{
                    fontSize: [16, 16],
                    lineHeight: [21, 21],
                    fontWeight: 700
                  }}
                >
                  ¿Quieres hacerlo tú?
                </Button>
              )}
              {hasAlliance && (
                <ul className="header__access-alianza">
                  <div className="header__logo-alianza">
                    <img src={allianceLogo} alt="logo alianza" />
                  </div>
                  {router?.pathname !== '/' ? (
                    <SelectorCurrency
                      changeCountry={changeCountry}
                      setShowOptions={setShowOptions}
                      showOptions={showOptions}
                      countrySelected={countrySelected}
                      alianza={alianza}
                      hasAlliance={hasAlliance}
                    />
                  ) : null}
                </ul>
              )}
            </div>
          </header>

          {/* Sidebar */}
          {accessLY && (
            <DynamicSidebar
              navData={navDataMobile}
              userContextData={userContextData}
              onClickLogout={onClickLogout}
              router={router}
              isScrolling={forceBgWhite}
              countrySelected={countrySelected}
              setCountrySelected={setCountrySelected}
              setCurrency={setCurrency}
              changeCountry={changeCountry}
              showAnnouncement={showAnnouncement}
            />
          )}
        </>
      );
    // Desktop
    default:
      return (
        <header
          className={cx('header-Wrapper')}
          style={{
            marginTop:
              showAnnouncement && !isHidden
                ? `calc(${height}px + ${forceBgWhite ? '0px' : '27px'})`
                : forceBgWhite
                ? '0px'
                : '27px'
          }}
        >
          <div
            className={cx('header', 'header-checkout', {['header--shadow']: forceBgWhite})}
          >
            <section className={cx('header__content')}>
              {/* Links */}
              <div className="header__links">
                {/* Logo */}
                {logoLY && <HeaderLogo path={router.asPath} alianza={alianza} />}

                {/* Navbar */}
                {navbarLY && (
                  <ul className="header__links_navbar">
                    {/* Proyectos */}
                    <a
                      href="https://www.organizaciones.crackthecode.la/proyectos"
                      target="_blank"
                      className="header__links_navbar_link"
                      rel="noreferrer"
                    >
                      <li>Proyectos</li>
                    </a>

                    {/* Blog */}
                    <a
                      href="https://blog.crackthecode.la/"
                      target="_blank"
                      className="header__links_navbar_link"
                      rel="noreferrer"
                    >
                      <li>Blog</li>
                    </a>

                    {/* Nosotros */}
                    <DynamicDropdown
                      eventNameAnalytics="nosotros_menu"
                      name="Nosotros"
                      arrow
                      option="nosotros"
                      type="simple"
                    />
                  </ul>
                )}
              </div>
              {hasAlliance && (
                <ul className="header__access-alianza">
                  <div className="header__logo-alianza">
                    <img src={allianceLogo} alt="logo alianza" />
                  </div>
                  {router?.pathname !== '/' ? (
                    <SelectorCurrency
                      changeCountry={changeCountry}
                      setShowOptions={setShowOptions}
                      showOptions={showOptions}
                      countrySelected={countrySelected}
                      alianza={alianza}
                      hasAlliance={hasAlliance}
                    />
                  ) : null}
                </ul>
              )}

              {/* Access */}
              {accessLY && (
                <ul className="header__access">
                  {/* Elegir país */}
                  {accessLY && router?.pathname !== '/' && (
                    <SelectorCurrency
                      changeCountry={changeCountry}
                      setShowOptions={setShowOptions}
                      showOptions={showOptions}
                      countrySelected={countrySelected}
                    />
                  )}
                  {userAndLogin && (
                    <>
                      {/* Ingresar */}
                      {!userContextData.loading && !userContextData.data && (
                        <>
                          <div className="header__login_navbar">
                            <DynamicDropdown
                              name="Log in"
                              arrow
                              option="login"
                              type="simple"
                            />
                          </div>
                        </>
                      )}

                      {/* User */}
                      {!userContextData.loading &&
                        userContextData.data &&
                        !onlySelectCountry && (
                          <li className="header__access_link logged-in">
                            <DynamicUserDropdown
                              data={userContextData.data}
                              onClickLogout={onClickLogout}
                              isLetterAvatar
                            />
                          </li>
                        )}
                    </>
                  )}
                </ul>
              )}

              {/* Information */}
              {informationLY && (
                <Button
                  type="scroll"
                  scrollOptions={{
                    to: 'contact',
                    offset: {
                      mobile: {
                        hidden: -heights.mobile.header,
                        visible: 0
                      },
                      desktop: {
                        hidden: -heights.desktop.header,
                        visible: 0
                      }
                    }
                  }}
                  variant="black"
                  width="215px"
                  height="40px"
                  textOptions={{
                    fontSize: [16, 16],
                    lineHeight: [21, 21],
                    fontWeight: 700
                  }}
                >
                  ¿Quieres hacerlo tú?
                </Button>
              )}
            </section>
          </div>
        </header>
      );
  }
};

Header.propTypes = {
  headerType: string,
  userContextData: shape()
};

export default Header;
